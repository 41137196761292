import $ from 'jquery';
import linemate from 'linemate';
import Glide from '@glidejs/glide';
import EqualHeight from 'storm-equal-height';

const setConnections = () => {
  linemate.clear();
  const options = {
    color: '#004C45',
    width: 4,
    exitPoint: 'bottom',
  };
  linemate.connect('.nodeA', options);
  linemate.connect('.nodeB', options);
  linemate.connect('.nodeC', options);
};

const closeMobileMenu = () => {
  $('.navigation').toggleClass('is-open');
  $('body').toggleClass('is-fixed');
  $('.navigation-mobile-panel').toggleClass('is-open');
  $('.navigation-mobile-menu').removeClass('is-hidden');
  $('.navigation-mobile-link').toggleClass('is-hidden');
};

$(document).ready(() => {
  // Mobile menu
  $('.js-navigation-button').on('click', (event) => {
    event.preventDefault();
    $('.navigation').toggleClass('is-open');
    $('.navigation-mobile-link').toggleClass('is-hidden');
    $('.navigation-standpunten-close').removeClass('is-open');
    $('.navigation-standpunten').removeClass('is-open');
    $('.navigation-mobile-panel').toggleClass('is-open');
    $('body').toggleClass('is-fixed');
  });

  // Standpunten
  $('.js-standpunten').on('click', (event) => {
    event.preventDefault();
    $('.navigation').toggleClass('is-open');
    $('.navigation-standpunten-close').toggleClass('is-open');
    $('.navigation-standpunten').toggleClass('is-open');
    $('body').toggleClass('is-fixed');
    $('.navigation-mobile-menu').toggleClass('is-hidden');
  });

  $('.js-standpunten-close').on('click', (event) => {
    event.preventDefault();
    $('.navigation').toggleClass('is-open');
    $('body').toggleClass('is-fixed');
    $('.navigation-standpunten').toggleClass('is-open');
    $('.navigation-standpunten-close').toggleClass('is-open');
    $('.navigation-mobile-menu').toggleClass('is-hidden');
  });

  $('.js-mobile-menu-close').on('click', (event) => {
    event.preventDefault();
    closeMobileMenu();
  });

  // Gallery
  const $splitGlide = $('.header-gallery');
  const splitGlide = new Glide($splitGlide[0], {
    gap: 0,
    autoplay: 3500,
    hoverpause: false,
    animationDuration: 1000,
  });
  splitGlide.mount();

  // Equal height
  EqualHeight.init('.js-equal-height');

  // Equal height
  EqualHeight.init('.js-agenda-height');

  // Connection lines
  $(window).resize(() => {
    setConnections();
  });
  setConnections();

  // Smooth scrolling
  $('a[href*="#"]').not('[href="#"]').not('[href="#0"]')
    .click((event) => {
      const $this = $(event.currentTarget)[0];

      if (
        location.pathname.replace(/^\//, '') === $this.pathname.replace(/^\//, '')
        &&
        location.hostname === $this.hostname
      ) {
        let target = $($this.hash);
        target = target.length ? target : $(`[name=${$this.hash.slice(1)}]`);
        if (target.length) {
          event.preventDefault();

          if ($('.navigation').hasClass('is-open')) {
            closeMobileMenu();

            setTimeout(() => {
              $('html, body').animate({
                scrollTop: target.offset().top - $('.navigation').outerHeight(),
              }, 500);
            }, 500);
          } else {
            $('html, body').animate({
              scrollTop: target.offset().top - $('.navigation').outerHeight(),
            }, 500);
          }
        }
      }
    });
});
